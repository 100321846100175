import { GuideCardProps } from "@components/guideCard/GuideCard";

const fullyDigitalTest: GuideCardProps = {
  id: "digital-test",
  title: "Fully Digital Test",
  text: "Order a fully digital test and get results in minutes. This test has not yet been approved by the FDA and is currently available for investigational or research purposes only.",
  link: "/order/source-selection",
  imageUrl: "/images/illustration-digital-test3.png"
};

const cliaTest: GuideCardProps = {
  id: "digital-test1",
  title: "CLIA Test",
  text: "Fill in patient information and send a physical tissue to our CLIA-certified laboratory. Results in 5 days. No digital imaging required. This test is available for clinical and commercial purposes. Available soon.",
  link: "/order/lab-test",
  imageUrl: "/images/illustration-clia2.png"
};

export { fullyDigitalTest, cliaTest };
