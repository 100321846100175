import OrderRepository, { Order } from "@/repository/order";
import { Patient, PatientRepository } from "@/repository/patient";
import { analytics } from "@config/firebase";
import { LabTestForm, StandardTestForm } from "@utils/interface/order";
import axios from "axios";
import { logEvent } from "firebase/analytics";
import { User } from "firebase/auth";

const addDigitalTestOrder = async (
  orderFormData: StandardTestForm,
  authUser: User,
  folderId: string,
  physician: string,
  numberOfMRIFiles: number,
  numberOfHistologyFiles: number
): Promise<string[]> => {
  const patient = new Patient(
    authUser.uid,
    orderFormData.patient.demography,
    orderFormData.patient.staging,
    orderFormData.patient.foci,
    []
  );
  const patientId = await PatientRepository.addPatientEntry(patient);
  const order = new Order(
    authUser.uid,
    patientId,
    folderId,
    physician,
    "Processed",
    numberOfMRIFiles,
    numberOfHistologyFiles,
    "Digital"
  );
  const orderId = await OrderRepository.addOrderEntry(order);
  await PatientRepository.updatePatientOrdersField(patientId, orderId);

  logEvent(analytics, "addDigitalTestOrder");

  const res = await axios.post("https://dev-api-q3ppcxhoqa-uk.a.run.app/predict/", {
    user_id: authUser.uid,
    order_id: orderId,
    folder_id: folderId
  });
  console.log(res);

  return [orderId, patientId];
};

const addLabTestOrder = async (formData: LabTestForm, authUser: User, physician: string): Promise<void> => {
  const patient = new Patient(
    authUser.uid,
    formData.patient.demography,
    formData.patient.staging,
    formData.patient.foci,
    []
  );
  const patientId = await PatientRepository.addPatientEntry(patient);
  const order = new Order(authUser.uid, patientId, null, physician, "In Progress", 0, 0, "Lab");
  const orderId = await OrderRepository.addOrderEntry(order);
  await PatientRepository.updatePatientOrdersField(patientId, orderId);

  logEvent(analytics, "addLabTestOrder");
};

export { addDigitalTestOrder, addLabTestOrder };
