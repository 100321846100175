import { GuideCardProps } from "@components/guideCard/GuideCard";

const insertDataManually: GuideCardProps = {
  id: "manual-test-data",
  title: "Insert Data Manually",
  text: "Order the test by manually providing information about the patient, their disease, and upload any available resources (such as MRI or digital pathology images).",
  link: "/order/standard-test",
  imageUrl: "/images/illustration-digital-test2.png"
};

const importDataFromEhr: GuideCardProps = {
  title: "Import Data from EHR",
  text: "Leverage the power of EHR integration to make test ordering a breeze. The EHR-Enabled Test Ordering feature lets you import patient data directly from leading EHR systems, ensuring a smooth, accurate, and time-saving experience.",
  imageUrl: "/images/system-data.png",
  disable: true,
  buttonText: "Available soon"
};

export { importDataFromEhr, insertDataManually };
