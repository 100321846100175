import FormDropDown from "@components/form/FormDropDown";
import { Box, Typography } from "@mui/material";
import { formCategoryNDropDown, formCategoryTDropDown } from "./constants";

/**
 * @fileoverview This file contains the ClinicalStaging component.
 */

/**
 * Clinical Staging component.
 * Order Test Subcomponent that handles the clinical staging of the patient.
 * */
const ClinicalStaging = () => {
  return (
    <>
      <Typography variant="h6" sx={{ mt: 4 }}>
        Clinical Staging
      </Typography>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
        <FormDropDown {...formCategoryTDropDown} />
        <FormDropDown {...formCategoryNDropDown} />
      </Box>
    </>
  );
};
export default ClinicalStaging;
