import { Button, Typography } from "@mui/material";
import { StandardTestForm } from "@utils/interface/order";
import { useFieldArray, useFormContext, UseFormReturn } from "react-hook-form";
import { CancerFocus } from "./CancerFocus";

/**
 * @fileoverview This file contains the CancerFoci component and its props type declaration.
 * */

/** CancerFoci Props Type Declaration**/
interface CancerFociProps {
  methods: UseFormReturn<StandardTestForm, any>;
}

/**
 * CancerFoci component.
 * Order Test Subcomponent that handles the cancer foci, allowing the user to add individual Cancer Focus to the form
 * Cancer Focus components are generated based on the number of foci fields in the form.
 * Clicking the button  allows the user to add more foci.
 * */
const CancerFoci = (props: CancerFociProps) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control: control, name: "patient.foci" });

  return (
    <>
      <Typography variant="h6" sx={{ mt: 4 }}>
        Cancer Foci
      </Typography>

      {fields.map((field, index) => {
        return <CancerFocus key={field.id} index={index} remove={remove} methods={props.methods} />;
      })}
      <Button
        variant="contained"
        sx={{ m: 2, mt: 0 }}
        onClick={() =>
          append({
            focus_size: 0,
            estrogen: "",
            progesterone: "",
            her2: "",
            ki67: 0,
            histology: { grade: "", subtypes: [] }
          })
        }
      >
        Add more foci (+)
      </Button>
    </>
  );
};
export default CancerFoci;
