import { IPatient } from "@/repository/order";
import { ICaseStudy } from "@components/caseStudies/CaseStudyBox";
interface CaseReferencePatient extends IPatient {
  case_study: ICaseStudy;
}
const examples: { [id: string]: CaseReferencePatient } = {
  "Example A": {
    demography: {
      name: "Gail Nielson",
      dob: "1957-05-12"
    },
    staging: {
      tnmCategoryN: "N1mi",
      tnmCategoryT: "T1"
    },
    foci: [
      {
        focusSize: 5,
        estrogen: "er-positive",
        progesterone: "pr-positive",
        her2: "her2-negative",
        ki67: 7,
        histology: {
          grade: "Low Grade",
          subtypes: ["Invasive ductal carcinoma", "Ductal carcinoma in situ"]
        }
      }
    ],
    case_study: {
      name: "Gail Nielson",
      oncotype: "High",
      ataraxisScore: "Low",
      description: "Early-stage HR+ patient who received adjuvant chemotherapy that potentially could have spared"
    }
  },
  "Example B": {
    demography: {
      name: "Patricia Robinson",
      dob: "1966-11-15"
    },
    staging: {
      tnmCategoryN: "N0",
      tnmCategoryT: "T3"
    },
    foci: [
      {
        focusSize: 9,
        estrogen: "er-positive",
        progesterone: "pr-positive",
        her2: "her2-positive",
        ki67: 25,
        histology: {
          grade: "Intermediate Grade",
          subtypes: ["Invasive ductal carcinoma"]
        }
      }
    ],
    case_study: {
      name: "Patricia Robinson",
      oncotype: "High",
      ataraxisScore: "Low",
      description: "Early-stage HR+ patient who received adjuvant chemotherapy that potentially could have spared"
    }
  },
  "Example C": {
    demography: {
      name: "Joni Sampson",
      dob: "1983-09-16"
    },
    staging: {
      tnmCategoryN: "N2a",
      tnmCategoryT: "T4a"
    },
    foci: [
      {
        focusSize: 17,
        estrogen: "er-negative",
        progesterone: "pr-negative",
        her2: "her2-negative",
        ki67: 55,
        histology: {
          grade: "High Grade",
          subtypes: ["Invasive ductal carcinoma"]
        }
      }
    ],
    case_study: {
      name: "Joni Sampson",
      oncotype: "High",
      ataraxisScore: "Low",
      description: "Early-stage HR+ patient who received adjuvant chemotherapy that potentially could have spared"
    }
  },
  "Naomi Santiago": {
    demography: {
      name: "Naomi Santiago",
      dob: "1983-09-16"
    },
    staging: {
      tnmCategoryN: "N2a",
      tnmCategoryT: "T4a"
    },
    foci: [
      {
        focusSize: 11,
        estrogen: "er-negative",
        progesterone: "pr-negative",
        her2: "her2-negative",
        ki67: 70,
        histology: {
          grade: "High Grade",
          subtypes: ["Invasive ductal carcinoma"]
        }
      },
      {
        focusSize: 44,
        estrogen: "er-negative",
        progesterone: "pr-negative",
        her2: "her2-negative",
        ki67: 70,
        histology: {
          grade: "High Grade",
          subtypes: ["Invasive ductal carcinoma", "Ductal carcinoma in situ"]
        }
      }
    ],
    case_study: {
      name: "Naomi Santiago",
      oncotype: "High",
      ataraxisScore: "Low",
      description: "Early-stage HR+ patient who received adjuvant chemotherapy that potentially could have spared"
    }
  },
  "Frances Puente": {
    demography: {
      name: "Frances Puente",
      dob: "1973-09-16"
    },
    staging: {
      tnmCategoryN: "N2a",
      tnmCategoryT: "T4a"
    },
    foci: [
      {
        focusSize: 11,
        estrogen: "er-negative",
        progesterone: "pr-negative",
        her2: "her2-negative",
        ki67: 70,
        histology: {
          grade: "High Grade",
          subtypes: ["Invasive ductal carcinoma"]
        }
      }
    ],
    case_study: {
      name: "Frances Puente",
      oncotype: "High",
      ataraxisScore: "Low",
      description: "Early-stage HR+ patient who received adjuvant chemotherapy that potentially could have spared"
    }
  }
};

export { examples, type CaseReferencePatient };
