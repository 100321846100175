import { StandardTestForm } from "@utils/interface/order";
import { type User } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { type UploadTaskSnapshot } from "firebase/storage";
import { Dispatch, SetStateAction } from "react";
import { stateChangeHandler, storageErrorHandler, uploadFileToStorage } from ".";

const root = "patient";

enum FileType {
  MRI = "mri",
  Histology = "histology"
}

/**
 * State change handler for monitor the progress of current processes
 *
 * @param {FileList} files
 * @param {number} index
 * @param {Dispatch<SetStateAction<number[]>>} setProgresses
 * @return {*}  {((arg0: UploadTaskSnapshot) => void)}
 */
const dicomStateChangeHandler = (
  files: FileList,
  index: number,
  setProgresses: Dispatch<SetStateAction<number[]>>
): ((arg0: UploadTaskSnapshot) => void) => {
  const callback = (snapshot: UploadTaskSnapshot) => {
    const progress = snapshot.bytesTransferred / snapshot.totalBytes / files.length;
    setProgresses((prevProgresses) => {
      const newProgresses = [...prevProgresses];
      newProgresses[index] = progress;
      return newProgresses;
    });
  };

  return (snapshot: UploadTaskSnapshot) => {
    return stateChangeHandler(snapshot, callback);
  };
};

/**
 * Upload fileList to storage
 * Path is determined by file type prefixed with filePath
 * Following with subscriber to subscribe state change info
 *
 * @param {string} filePath
 * @param {FileType} fileType
 * @param {FileList} files
 * @param {Dispatch<SetStateAction<number[]>>} setProgresses
 * @return {*}
 */
const uploadDicomsToStorage = async (
  filePath: string,
  fileType: FileType,
  files: FileList,
  setProgresses: Dispatch<SetStateAction<number[]>>
) => {
  const promises = [];
  for (let i = 0; i < files.length; i++) {
    const uploadTask = uploadFileToStorage(files[i], `${root}/${fileType}/${filePath}/${files[i].name}`);
    promises.push(uploadTask);
    uploadTask.on("state_changed", dicomStateChangeHandler(files, i, setProgresses), storageErrorHandler);
  }
  return Promise.all(promises);
};

/**
 * Upload both optional MRI and Histology files to storage with path string
 * string with `patient`, independent file type, userId and folderId
 * //NOTE - FolderId is constructed using current timestamp
 *
 * @param {User} authUser
 * @param {StandardTestForm} orderFormData
 * @param {Dispatch<SetStateAction<number[]>>} setProgresses
 * @return {*}  {Promise<string>} Return folderId
 */
const uploadMRIAndHistologyToStorage = (
  authUser: User,
  orderFormData: StandardTestForm,
  setProgresses: Dispatch<SetStateAction<number[]>>
): Promise<string> => {
  // Use timestamp as folder name
  const folderId = Timestamp.now().toMillis().toString();
  const filePath = `${authUser.uid}/${folderId}`;
  const promises = [];
  if (orderFormData.orderFilesMri)
    promises.push(uploadDicomsToStorage(filePath, FileType.MRI, orderFormData.orderFilesMri, setProgresses));
  if (orderFormData.orderFilesHistology)
    promises.push(
      uploadDicomsToStorage(filePath, FileType.Histology, orderFormData.orderFilesHistology, setProgresses)
    );
  return Promise.all(promises).then(() => folderId);
};

export { FileType, uploadMRIAndHistologyToStorage };
