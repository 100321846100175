import CancerFoci from "@components/orderTest/CancerFoci";
import ClinicalStaging from "@components/orderTest/ClinicalStaging";
import Demographics from "@components/orderTest/Demographics";
import UploadFile from "@components/orderTest/UploadFile";
import { LinearProgressWithLabel } from "@components/progressBar/LinearProgressWithLabel";

import { useCurrentUserData } from "@hooks/user";
import Send from "@mui/icons-material/Send";
import { Box, Button, CircularProgress, Grid, LinearProgress, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { IPatientAndOrderId, StandardTestForm } from "@utils/interface/order";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import RealWorldExamples from "@components/orderTest/orderTest3/RealWorldExamples";
import WithOrderFormLayOut from "@hoc/withOrderFormLayOut";
import { addDigitalTestOrder } from "@services/orderService";
import { uploadMRIAndHistologyToStorage } from "@storage/dicom";
import { useAuth } from "@store/auth/model";
import "../Report.scss";

const StandardTest = () => {
  const [progresses, setProgresses] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const user = useCurrentUserData();

  const methods = useForm<StandardTestForm>({
    defaultValues: {
      patient: {
        foci: [
          { focusSize: 0, estrogen: "", progesterone: "", her2: "", ki67: 0, histology: { grade: "", subtypes: [] } }
        ]
      }
    }
  });

  console.log(methods.watch());

  const navigate = useNavigate();
  const auth = useAuth();

  const onSubmit = async (orderFormData: StandardTestForm) => {
    setLoading(true);
    if (!auth) {
      alert("Current user not found");
      return;
    }
    const physician = user.lastname + ", " + user.firstname;
    if (!orderFormData.orderFilesMri && !orderFormData.orderFilesHistology) {
      const ans = confirm(
        "You have not uploaded MRI or digital pathology imaging. If you submit the order without these files, the results might not be accurate. Are you sure you want to proceed?"
      );
      if (!ans) {
        setLoading(false);
        return;
      }
    }
    const folderId = await uploadMRIAndHistologyToStorage(auth, orderFormData, setProgresses);

    setProgresses([]);
    setSubmitting(true);
    const numberOfMRIFiles = orderFormData.orderFilesMri ? orderFormData.orderFilesMri.length : 0;
    const numberOfHistologyFiles = orderFormData.orderFilesHistology ? orderFormData.orderFilesHistology.length : 0;
    const [orderId, patientId] = await addDigitalTestOrder(
      orderFormData,
      auth,
      folderId,
      physician,
      numberOfMRIFiles,
      numberOfHistologyFiles
    );

    setLoading(false);
    setSubmitting(false);

    const state: IPatientAndOrderId = {
      orderId: orderId,
      patientId: patientId
    };

    navigate("/report", { state });
  };

  const { setValue, handleSubmit } = methods;

  return (
    <>
      <Grid id="fill-test-form" item container>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Demographics />
            <ClinicalStaging />
            <CancerFoci methods={methods} />
            <UploadFile category={"MRI"} />
            <UploadFile category={"Histology"} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  id="test-order-submit"
                  type="submit"
                  size="large"
                  value="Generate Report"
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  endIcon={<Send />}
                >
                  Submit test order
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
              </Box>
              {progresses.length !== 0 && (
                <Box className="mt-1" sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" color="text.secondary">
                    Uploading images...
                  </Typography>
                  <LinearProgressWithLabel value={progresses.reduce((a, b) => a + b, 0) * 100} />
                </Box>
              )}
              {submitting && (
                <Box className="mt-1" sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" color="text.secondary">
                    Submitting order...
                  </Typography>
                  <LinearProgress />
                </Box>
              )}
            </Box>
          </form>
        </FormProvider>
      </Grid>
      <Grid item lg={6}>
        <RealWorldExamples setValue={setValue} />
      </Grid>
    </>
  );
};

export default WithOrderFormLayOut(StandardTest);
