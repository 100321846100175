import FormInputField from "@components/form/FormInputField";
import { Box, Typography } from "@mui/material";

/**
 * @fileoverview This file contains the Demographics component.
 * */

/**
 * Demographics component.
 * Order Test Subcomponent that handles the logic for demographic data of the patient.
 * */
const Demographics = () => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Demographic data
      </Typography>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
        <FormInputField name="patient.demography.name" label="Full Name" type="text" required />
        <FormInputField name="patient.demography.dob" label="Date of Birth" type="date" required shrink={true} />
      </Box>
    </>
  );
};

export default Demographics;
